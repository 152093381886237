export default [
  {
		path: "/login",
		name: "Login",
		component: () =>
			import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
		children: [
			{
				path: "/main",
				name: "Main",
				component: () =>
					import(/* webpackChunkName: "main" */ "../views/MainView.vue"),
			},
		],
	},
];