<template>
  <div class="home">
    <h1>Hellö Gri</h1>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
}
</script>
