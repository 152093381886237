import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

import about from "./about";
import login from "./login";

const routes = [
	{
		path: "/",
		name: "Home",
		meta: { title: "Home" },
		component: HomeView,
	},
	...about,
	...login,
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

const DEFAULT_TITLE = "Vue Deneme Sitesi ❤️";
router.afterEach(to => {
	// see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
	Vue.nextTick(() => {
		document.title = to.meta.title || DEFAULT_TITLE;
	});
});

export default router;
