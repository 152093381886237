<template>
  <div id="app">
    <nav>
      <router-link
        v-for="route in routes"
        :key="route.name"
        :to="route.path"
      >
        {{ route.name }}
      </router-link>
    </nav>
    <router-view :key="$route.fullPath"  />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      routes: this.$router.options.routes
    }
  },
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    color: #2c3e50;
    text-decoration: none;
    text-transform: capitalize;
    padding: 1rem;

    &:hover {
      color: #42b983;
    }

    &.router-link-exact-active {
      color: #fff;
      font-weight: bold;
      background-color: #42b983;
    }
  }
}
</style>
