export default [
  {
		path: "/about",
		name: "About",
		meta: {
			title: "About"
		},
		component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
		children: [
			{
				path: "contact",
				name: "Contact",
				meta: {
					title: "Contact"
				},
				component: () => import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
			},
			{
				path: "works",
				name: "Works",
				component: () => import(/* webpackChunkName: "works" */ "../views/WorksView.vue"),
			}
		],
	},
];